// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import ReactGA from 'react-ga4';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import Pagination from 'components/paginationGeneral';
import ListPosts from 'components/blog/listPosts';
import BreadCrumb from 'components/breadCrumb';

// Context
import { HeaderContext } from 'context/header';

// Image
import shareImg from 'images/logos/logo-hsl-share.png';

const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
  }
`;

const Title = styled.h1`
  margin-bottom: 4.375rem;
  width: 35.625rem;
  font-weight: bold;
  color: #231f20;
  font-size: 3.375rem;
  word-break: break-word;

  @media ${device.laptop} {
    margin: 2.375rem 0 2.375rem 0.7rem;
    width: 100%;
    font-size: 2rem;
  }

  @media ${device.tablet} {
    margin-left: 0;
  }

  @media ${device.mobile} {
    margin-top: 1.375rem;
    font-size: 1.5rem;
  }
`;

const Box = styled.div`
  ${props =>
    props.hasSpacing &&
    css`
      margin-bottom: 4.375rem;

      @media ${device.laptop} {
        margin-bottom: 1.875rem;
      }
    `};
`;

const renderPage = (isDesktop, posts, pageContext) => {
  const categorySlug = pageContext?.categorySlug ?? '-';
  const categoryTitle = pageContext?.categoryTitle ?? '-';
  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / 14'}>
        <BreadCrumb
          markdown={`1. [Home](/) › [Blog](/blog) › ${categoryTitle}`}
          marginTop
        />
        <Title>{categoryTitle}</Title>
        <Part gridColumn={'1 / -1'}>
          <ListPosts
            title=""
            headerLinkTitle=""
            headerLink=""
            posts={posts}
            pageContext={pageContext}
          />
        </Part>
        <Box hasSpacing={pageContext.numPages <= 1} />
        <Pagination
          dataPagination={pageContext}
          isBlog
          blogLink={categorySlug}
          isDesktop={isDesktop}
        />
      </Part>
    </GridContainer>
  );
};

const AllPosts = ({
  data: {
    gcms: { posts },
  },
  pageContext,
  location,
}) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('8.075rem');
      setMainMarginTopMobile('8.075rem');
    } else {
      setMainMarginTopLaptop('6rem');
      setMainMarginTopMobile('6rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  useEffect(() => {
    // Inicializando o Google Analytics
    if (Boolean(pageContext?.tagGa)) {
      ReactGA.initialize(pageContext?.tagGa);
      ReactGA.send('pageview');
    }
  }, []);

  const getPathname =
    location.pathname && location.pathname.split('/blog/').join('');
  const cleanPathname = getPathname.split('/').join('');

  const getCurrentTag =
    posts &&
    posts.map(item => item.categories.filter(el => el.tag === cleanPathname));

  const renderSEODescription =
    (getCurrentTag?.[0] && getCurrentTag[0][0]?.tagDescriptionSeo) ||
    (getCurrentTag?.[0] &&
      getCurrentTag[0][0]?.localizations?.[0]?.tagDescriptionSeo);

  const renderSEOImage = getCurrentTag?.[0]?.tagOgImageSeo?.handle;

  return (
    <Layout>
      <SEO
        titlePosition="left"
        title={pageContext?.categoryTitle || 'Artigo'}
        description={renderSEODescription || '-'}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${renderSEOImage}` ||
          shareImg
        }
      />
      {renderPage(isDesktop, posts, pageContext)}
    </Layout>
  );
};

export const AllPageQuery = graphql`
  query AllPostsPageQuery($tag: String) {
    gcms {
      posts(
        where: { categories_some: { tag: $tag } }
        orderBy: date_ASC
        locales: [pt_BR, en, es]
      ) {
        id
        author
        doctors(first: 1000, locales: [pt_BR, en]) {
          id
          id_api
          active
          assignment
          name
        }
        markdown
        slugPost
        topic
        title
        date
        createdAt
        localizations {
          date
          assetpicker {
            handle
            width
            height
          }
        }
        categories {
          tag
          tagDescriptionSeo
          localizations {
            tagDescriptionSeo
          }
          tagOgImageSeo {
            handle
            height
            width
          }
        }
        assetpicker {
          handle
          height
          width
        }
      }
    }
  }
`;

export default AllPosts;
